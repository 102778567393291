<template>
  <div class="login" v-if="isLoading" :class="{ pc: isPc }">
    <div class="login-box">
      <template v-if="isPc">
        <div class="login-box-banner" v-if="bannerList.length === 0 || bannerList.length === 1">
          <img
            :src="bannerList.length ? bannerList[0].url : banner"
            @click="bannerList.length && skipUrl(bannerList[0].jumpLink)"
          />
        </div>
        <t-swiper class="login-box-banner" v-else>
          <t-swiper-item v-for="(item, index) in bannerList" :key="index">
            <img :src="item.url" @click="skipUrl(item.jumpLink)" />
          </t-swiper-item>
        </t-swiper>
      </template>
      <div class="right">
        <template v-if="setNewPassword">
          <div class="right-tips">
            <div v-if="isPc" style="font-size: 20px; width: 34px; cursor: pointer" @click="tabActiveClick(tabActive)">
              ⬅
            </div>
            <div v-else style="margin-bottom: 5px">
              <icon @click="tabActiveClick(tabActive)" name="chevron-left" size="32px" />
            </div>
            <div>设置新密码</div>
          </div>
          <div class="right-key">请输入新密码</div>
          <t-input type="password" v-model:value="password" placeholder="请输入" @blur="blur" />
          <div class="right-key">请再次输入新密码</div>
          <t-input type="password" v-model:value="newPassword" placeholder="请输入" />
          <div class="right-btn">
            <span>{{ textInfo }}</span>
            <t-button size="large" @click="newPasswordOnok">确定</t-button>
          </div>
        </template>
        <template v-else-if="tabActive === 0 || tabActive === 1">
          <div class="right-tips">
            <div v-if="isPc">欢迎登录</div>
            <div v-else>欢迎</div>
            <div v-if="!isPc">请登录</div>
          </div>
          <div class="right-tab">
            <div :class="{ active: tabActive === 0 }" @click="tabActiveClick(0)">密码登录</div>
            <span>|</span>
            <div :class="{ active: tabActive === 1 }" @click="tabActiveClick(1)">验证码登录</div>
          </div>
          <template v-if="tabActive === 0">
            <div class="right-key">{{ isPc ? "请输入用户名/手机号/邮箱" : "账号" }}</div>
            <t-input v-model:value="userName" placeholder="请输入用户名/手机/邮箱" @enter="loginClick" />
            <div class="right-key">{{ isPc ? "请输入密码" : "密码" }}</div>
            <t-input type="password" v-model:value="password" placeholder="请输入密码" @enter="loginClick" />
          </template>
          <template v-if="tabActive === 1">
            <div class="right-key">{{ isPc ? "请输入手机号" : "手机号" }}</div>
            <t-input v-model:value="userName" placeholder="请输入手机号" @enter="loginClick" />
            <div class="right-key">{{ isPc ? "请输入验证码" : "验证码" }}</div>
            <t-input v-model:value="password" placeholder="请输入验证码" @enter="loginClick">
              <template #suffix>
                <span v-if="sendOut === 0" style="color: #3a63f3; cursor: pointer" @click="sendOutCode"
                  >发送验证码</span
                >
                <span v-else style="color: #999">{{ sendOut }} s</span>
              </template>
            </t-input>
          </template>
          <div class="right-check" ref="checkElement">
            <t-checkbox v-model:checked="checked">
              我已阅读并同意 <a href="javascript:;" @click="openPrivacy(0)">《使用协议》</a>及<a
                href="javascript:;"
                @click="openPrivacy(1)"
                >《隐私政策》</a
              >
            </t-checkbox>
          </div>
          <div class="right-btn">
            <span :style="{top:`${-tipTop}px`}">{{ textInfo }}</span>
            <t-button size="large" @click="loginClick" :disabled="isButton">立即登录</t-button>
          </div>
          <div class="right-policy">
            <!-- <div>没有帐号？<span @click="tabActiveClick(5)">立即注册</span></div> -->
            <div></div>
            <span @click="tabActiveClick(2)">忘记密码</span>
          </div>
          <div class="other-login">
            <div class="wx-login" @click="tabActiveClick(6)">
              <t-icon name="logo-wechat" style="color: #00c800; margin-right: 5px" />
              <span>微信登录</span>
            </div>
          </div>
        </template>
        <template v-else-if="tabActive === 2 || tabActive === 3 || tabActive === 4">
          <div class="right-tips">
            <div v-if="isPc" style="font-size: 20px; width: 34px; cursor: pointer" @click="tabActiveClick(0)">⬅</div>
            <div v-else style="margin-bottom: 5px">
              <icon @click="tabActiveClick(0)" name="chevron-left" size="32px" />
            </div>
            <div>找回密码</div>
          </div>
          <div class="right-tab">
            <div :class="{ active: tabActive === 2 }" @click="tabActiveClick(2)">
              {{ isPc ? "手机+验证码" : "手机验证" }}
            </div>
            <span>|</span>
            <div :class="{ active: tabActive === 3 }" @click="tabActiveClick(3)">
              {{ isPc ? "邮箱+验证码" : "邮箱验证" }}
            </div>
            <span>|</span>
            <div :class="{ active: tabActive === 4 }" @click="tabActiveClick(4)">{{ "无法验证" }}</div>
          </div>
          <template v-if="tabActive === 2 || tabActive === 3">
            <div class="right-key" v-if="isPc">{{ tabActive === 2 ? "请输入手机号" : "请输入邮箱" }}</div>
            <div class="right-key" v-else>{{ tabActive === 2 ? "手机号" : "邮箱" }}</div>
            <t-input v-model:value="userName" :placeholder="tabActive === 2 ? '请输入手机号' : '请输入邮箱'" />
            <div class="right-key">{{ isPc ? "请输入验证码" : "验证码" }}</div>
            <t-input v-model:value="password" placeholder="请输入验证码">
              <template #suffix>
                <span v-if="sendOut === 0" style="color: #3a63f3; cursor: pointer" @click="sendOutCode"
                  >发送验证码</span
                >
                <span v-else style="color: #999">{{ sendOut }} s</span>
              </template>
            </t-input>
            <div class="right-btn">
              <span>{{ textInfo }}</span>
              <t-button size="large" @click="loginClick">下一步</t-button>
            </div>
          </template>
          <template v-else>
            <div style="height: 306px">
              <div class="right-consulting">
                <div>
                  1.若帐号是由您的单位开通的，请联系单位管理员修改登录密码，如果不清楚管理员信息，可以联系客服询问。
                </div>
                <div>
                  2.若帐号是您自己注册的，很抱歉目前除手机号或邮箱验证外不提供其他找回密码方式，您也可以联系客服了解更多详情。
                </div>
              </div>
              <div class="right-timer">工作时间：9:00-18:00</div>
              <div class="right-timer">客服电话：027-59379753</div>
            </div>
          </template>
        </template>
        <template v-else-if="tabActive === 5">
          <div class="right-tips">
            <div v-if="!isPc" style="margin-bottom: 5px">
              <icon @click="tabActiveClick(0)" name="chevron-left" size="32px" />
            </div>
            <div>{{ isPc ? "注册" : "注册账号" }}</div>
          </div>
          <div class="right-key">{{ isPc ? "请输入手机号" : "手机号" }}</div>
          <t-input v-model:value="userName" placeholder="请输入手机号" />
          <div class="right-key">{{ isPc ? "请输入验证码" : "验证码" }}</div>
          <t-input v-model:value="password" placeholder="请输入验证码">
            <template #suffix>
              <span v-if="sendOut === 0" style="color: #3a63f3; cursor: pointer" @click="sendOutCode">发送验证码</span>
              <span v-else style="color: #999">{{ sendOut }} s</span>
            </template>
          </t-input>
          <div class="right-check" ref="checkElement">
            <t-checkbox v-model:checked="checked">
              我已阅读并同意 <a href="javascript:;" @click="openPrivacy(0)">《使用协议》</a>及<a
                href="javascript:;"
                @click="openPrivacy(1)"
                >《隐私政策》</a
              >
            </t-checkbox>
          </div>
          <div class="right-btn">
            <span :style="{top:`${-tipTop}px`}">{{ textInfo }}</span>
            <t-button size="large" @click="loginClick" :disabled="isButton">注册</t-button>
          </div>
          <div class="right-policy">
            <div>已有帐号吗？<span @click="tabActiveClick(0)">立即登录</span></div>
          </div>
        </template>
        <template v-else-if="tabActive === 6">
          <div class="right-qrcode">
            <div class="qrcode-title">
              <t-icon
                @click="tabActiveClick(0)"
                name="chevron-left"
                size="20px"
                style="margin-right: 11px; cursor: pointer"
              /><span>微信扫码登录</span>
            </div>
            <div class="qrcode-box">
              <iframe
                ref="qrcode"
                :src="qrcodeUrl()"
                width="300"
                height="400"
                frameborder="0"
                sandbox="allow-scripts allow-top-navigation allow-same-origin"
              ></iframe>
            </div>
            <div class="qrcode-explain">使用微信扫一扫,登录慧学慧研平台</div>
          </div>
        </template>
        <template v-else-if="tabActive === 7">
          <div class="bind-title">请输入要绑定的账号</div>
          <div class="right-tab">
            <div :class="{ active: bindTabActive === 0 }" @click="bindTabActiveClick(0)">账号密码</div>
            <span>|</span>
            <div :class="{ active: bindTabActive === 1 }" @click="bindTabActiveClick(1)">手机验证码</div>
          </div>
          <div class="bind-wx-account">
            <div class="account-box">
              <template v-if="bindTabActive === 0">
                <div class="right-key">{{ isPc ? "请输入用户名/手机号/邮箱" : "账号" }}</div>
                <t-input v-model:value="userName" placeholder="请输入用户名/手机/邮箱" @enter="bindWX" />
                <div class="right-key">{{ isPc ? "请输入密码" : "密码" }}</div>
                <t-input type="password" v-model:value="password" placeholder="请输入密码" @enter="bindWX" />
              </template>
              <template v-else>
                <div class="right-key">{{ isPc ? "请输入手机号" : "手机号" }}</div>
                <t-input v-model:value="userName" placeholder="请输入手机号" />
                <div class="right-key">{{ isPc ? "请输入验证码" : "验证码" }}</div>
                <t-input v-model:value="password" placeholder="请输入验证码">
                  <template #suffix>
                    <span v-if="sendOut === 0" style="color: #3a63f3; cursor: pointer" @click="sendOutCode"
                      >发送验证码</span
                    >
                    <span v-else style="color: #999">{{ sendOut }} s</span>
                  </template>
                </t-input>
              </template>
              <span>{{ textInfo }}</span>
              <div class="control-btn">
                <t-button block theme="default" size="large" @click="tabActiveClick(0)" :disabled="isButton"
                  >取消</t-button
                >
                <t-button block size="large" @click="bindWX" :disabled="isButton">绑定</t-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <hxhyPrivacy ref="hxhyPrivacyRef" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { setStore, getStore, clearStore } from "@/util/store";
import { isMobile, isEmail, validatePwd } from "@/util/validate";
import { MessagePlugin } from "tdesign-vue-next";
import { Encrypt } from "@/util/handelEncryptedData.js";
import { Icon } from "tdesign-icons-vue-next";
import {
  getUserToken,
  getSendSmsCode,
  getMobileToken,
  getSendEmailCode,
  getVeriSmsCode,
  getVeriEmailCode,
  getEditPassword,
  getAddRegister,
  getCheckToken,
  checkRedirectUrl,
  getSpaAfficheList,
  bindWechat,
} from "@/api/user.js";

import { useRouter, useRoute } from "vue-router";

import { hxhyPrivacy } from "hxhy-privacy";

const router = useRouter();
const route = useRoute();

const isLoading = ref(false);
const isButton = ref(true);

const wxcode = ref("");

const envList = {
  "dev-edu": "dev-edumain.zhixuehuijiao.cn",
  test: "testmain.huixuecloud.cn",
  pre: "premain.huixuecloud.cn",
};
const env_name =
  Object.keys(envList).find((v) => {
    return location.host.includes(v);
  }) || "";

const MOBILE_AGENTS = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
const isPc = ref(true);
MOBILE_AGENTS.forEach((item) => {
  if (navigator.userAgent.indexOf(item) > -1) {
    isPc.value = false;
  }
});
window.onresize = (a, b) => {
  isPc.value = true;
  MOBILE_AGENTS.forEach((item) => {
    if (navigator.userAgent.indexOf(item) > -1) {
      isPc.value = false;
    }
  });
};

window.overtime = () => {
  isLoading.value = true;
  clearStore();
  let redirect_url = route.query.redirect_url || route.query.redirect_uri;
  if (!redirect_url) {
    redirect_url = window.location.protocol + "//hxhy." + (env_name ? envList[env_name] : "web.zhixuehuijiao.cn");
  }
  checkRedirectUrl({
    clientId: route.query.client_id || "common",
    redirectUri: redirect_url,
  }).then((res) => {
    if (res.data.code === 0) {
      isButton.value = false;
    }
  });
};

const access_token = getStore({ name: "access_token" });
if (access_token) {
  getCheckToken({ token: access_token }).then((res) => {
    execAfterGetToken({ access_token });
  });
} else {
  window.overtime();
}

// 广告列表
const banner = require("@/assets/banner.png"); // 默认
const bannerList = ref([]);
getSpaAfficheList("login").then((res) => {
  if (res.data.code === 0) {
    bannerList.value = res.data.data || [];
  }
});
const skipUrl = (url) => {
  if (!url) return;
  const regexp = /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/;
  if (regexp.test(url)) {
    window.open(url, "_self");
  } else {
    window.open(`//${url}`, "_self");
  }
};

const tabActive = ref(0); //tab切换
const setNewPassword = ref(false); //重置密码页面
const userName = ref(""); //用户名
const password = ref(""); //密码
const newPassword = ref(""); //新密码
const sendOut = ref(0); //发送短信倒计时
const checked = ref(false); //勾选协议
const textInfo = ref(""); //提示语
const bindTabActive = ref(0); //tab切换

const tabActiveClick = (value) => {
  setNewPassword.value = false;
  userName.value = "";
  password.value = "";
  newPassword.value = "";
  textInfo.value = "";
  tabActive.value = value;
  bindTabActive.value = 0;
};

const bindTabActiveClick = (value) => {
  setNewPassword.value = false;
  userName.value = "";
  password.value = "";
  newPassword.value = "";
  textInfo.value = "";
  tabActive.value = 7;
  bindTabActive.value = value;
};

const sendOutCode = () => {
  if (tabActive.value === 1 || tabActive.value === 2 || tabActive.value === 5 || tabActive.value === 7) {
    if (!isMobile(userName.value)) {
      MessagePlugin.closeAll();
      MessagePlugin.warning("请输入正确的手机号");
    } else {
      let smsType;
      if (tabActive.value === 1 || tabActive.value === 7) {
        smsType = 0;
      } else if (tabActive.value === 5) {
        smsType = 1;
      } else {
        smsType = 2;
      }
      getSendSmsCode(userName.value, smsType).then((res) => {
        if (res.data.code === 0) {
          password.value = "";
          sendOut.value = 60;
          const timer = setInterval(() => {
            sendOut.value = sendOut.value - 1;
            if (sendOut.value === 0) {
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    }
  } else if (tabActive.value === 3) {
    const msg = isEmail(userName.value);
    if (!msg) {
      MessagePlugin.closeAll();
      MessagePlugin.warning("邮箱格式不正确");
    } else {
      getSendEmailCode(userName.value, 2).then((res) => {
        if (res.data.code === 0) {
          password.value = "";
          sendOut.value = 60;
          const timer = setInterval(() => {
            sendOut.value = sendOut.value - 1;
            if (sendOut.value === 0) {
              clearInterval(timer);
            }
          }, 1000);
        }
      });
    }
  }
};

const execAfterGetToken = (info) => {
  const redirect_url = route.query.redirect_url || route.query.redirect_uri
  if (redirect_url) {
    const arr = [];
    for (let i in route.query) {
      if (i !== "redirect_url" && i !== "redirect_uri") {
        arr.push(i + "=" + route.query[i]);
      }
    }
    window.location.href =
      redirect_url + "?token=" + info.access_token + (arr.length ? "&" + arr.join("&") : "");
  } else {
    window.location.href =
      "//" + (env_name ? envList[env_name] : "web.zhixuehuijiao.cn") + "?token=" + info.access_token;
  }
};

const execAfterBindScan = (info) => {
  setStore({
    name: "access_token",
    content: info.access_token,
  });
  const bindData = new FormData();
  bindData.append("state", "WX");
  bindData.append("code", wxcode.value);

  bindWechat(bindData, info.access_token).then((res) => {
    if (res.data.code === 0) {
      execAfterGetToken(info);
    } else {
      MessagePlugin.error("微信绑定失败");
    }
  });
};

const bindWX = () => {
  if (bindTabActive.value === 0) {
    if (!userName.value) {
      textInfo.value = "请输入用户名";
    } else if (!password.value) {
      textInfo.value = "请输入密码";
    } else {
      textInfo.value = "";
      getUserToken({ userName: userName.value, password: Encrypt(password.value) }).then((res) => {
        execAfterBindScan(res.data);
      });
    }
  } else {
    if (!userName.value) {
      textInfo.value = "请输入手机号";
    } else if (!password.value) {
      textInfo.value = "请输入验证码";
    } else {
      textInfo.value = "";
      getMobileToken({ userName: userName.value, password: password.value }).then((res) => {
        execAfterBindScan(res.data);
      });
    }
  }
};

const loginClick = () => {
  if (tabActive.value === 0) {
    if (!userName.value) {
      textInfo.value = "请输入用户名";
    } else if (!password.value) {
      textInfo.value = "请输入密码";
    } else if (!checked.value) {
      textInfo.value = "请阅读勾选相关协议";
    } else {
      textInfo.value = "";
      getUserToken({ userName: userName.value, password: Encrypt(password.value) }).then((res) => {
        setStore({
          name: "access_token",
          content: res.data.access_token,
        });
        execAfterGetToken(res.data);
      });
    }
  } else if (tabActive.value === 1) {
    if (!userName.value) {
      textInfo.value = "请输入手机号";
    } else if (!password.value) {
      textInfo.value = "请输入验证码";
    } else if (!checked.value) {
      textInfo.value = "请阅读勾选相关协议";
    } else {
      textInfo.value = "";
      getMobileToken({ userName: userName.value, password: password.value }).then((res) => {
        setStore({
          name: "access_token",
          content: res.data.access_token,
        });
        execAfterGetToken(res.data);
      });
    }
  } else if (tabActive.value === 2) {
    if (!userName.value) {
      textInfo.value = "请输入手机号";
    } else if (!password.value) {
      textInfo.value = "请输入验证码";
    } else {
      textInfo.value = "";
      getVeriSmsCode(userName.value, 2, password.value).then((res) => {
        if (res.data.code === 0) {
          password.value = "";
          setNewPassword.value = true;
        } else {
          textInfo.value = res.data.msg;
        }
      });
    }
  } else if (tabActive.value === 3) {
    if (!userName.value) {
      textInfo.value = "请输入邮箱";
    } else if (!password.value) {
      textInfo.value = "请输入验证码";
    } else {
      textInfo.value = "";
      getVeriEmailCode(userName.value, 2, password.value).then((res) => {
        if (res.data.code === 0) {
          password.value = "";
          setNewPassword.value = true;
        } else {
          textInfo.value = res.data.msg;
        }
      });
    }
  } else if (tabActive.value === 5) {
    if (!userName.value) {
      textInfo.value = "请输入手机号";
    } else if (!password.value) {
      textInfo.value = "请输入验证码";
    } else if (!checked.value) {
      textInfo.value = "请阅读勾选相关协议";
    } else {
      textInfo.value = "";
      getVeriSmsCode(userName.value, 1, password.value).then((res) => {
        if (res.data.code === 0) {
          getAddRegister(userName.value).then((res) => {
            if (res.data.code === 0) {
              getMobileToken({ userName: userName.value, password: res.data.data }).then((res) => {
                setStore({
                  name: "access_token",
                  content: res.data.access_token,
                });
                execAfterGetToken(res.data);
              });
            } else {
              textInfo.value = res.data.msg;
            }
          });
        } else {
          textInfo.value = res.data.msg;
        }
      });
    }
  }
};

const newPasswordOnok = () => {
  if (!validatePwd(password.value)) {
    textInfo.value = "请输入8-16位数字大小写字母或符号2种以上组合密码";
  } else if (password.value !== newPassword.value) {
    textInfo.value = "二次密码输入不一致";
  } else {
    textInfo.value = "";
    getEditPassword({
      phone: tabActive.value === 2 ? userName.value : undefined,
      email: tabActive.value === 3 ? userName.value : undefined,
      password: Encrypt(password.value),
    }).then((res) => {
      if (res.data.code === 0) {
        MessagePlugin.closeAll();
        MessagePlugin.success("密码修改成功");
        tabActiveClick(0);
      } else {
        textInfo.value = res.data.msg;
      }
    });
  }
};
const blur = () => {
  if (!validatePwd(password.value)) {
    MessagePlugin.closeAll();
    MessagePlugin.warning("请输入8-16位数字大小写字母或符号2种以上组合密码");
  }
};

const hxhyPrivacyRef = ref(null);
const openPrivacy = (type) => {
  hxhyPrivacyRef.value.onChange(type);
};

const qrcode = ref(null);

const qrcodeUrl = () => {
  const hosts = window.location.host.split(".").reverse();
  const host = hosts.length > 1 ? `${hosts[1]}.${hosts[0]}` : "huixuecloud.cn";
  const msgOrigin = btoa(encodeURIComponent(location.origin + location.pathname));
  return `//${env_name}wx-qrcodescan.${host}/qrcode?business=103&origin=${msgOrigin}`;
};

const execCodeLogin = (info) => {
  wxcode.value = info.code;
  if (info.notBind) {
    MessagePlugin.error("该微信尚未绑定账号，请先使用账号登录绑定");
    tabActiveClick(7);
  } else {
    setStore({
      name: "access_token",
      content: info.userInfo.access_token,
    });
    execAfterGetToken(info.userInfo);
  }
};

window.onmessage = (evt) => {
  const whiteSpace = ["zhixuehuijiao.cn", "huixuecloud.cn"];
  const hosts = evt.origin.split(".").reverse();
  const host = `${hosts[1]}.${hosts[0]}`;
  if (whiteSpace.some((safe) => host.includes(safe))) {
    if (evt.data.code) {
      execCodeLogin(evt.data);
    }
  }
};

const checkElement = ref(null);
const tipTop = ref(55)
onMounted(() => {
  tipTop.value = checkElement.value.clientHeight + 33
});
</script>
<style lang="scss" scoped>
.login {
  flex: 1;
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, rgba(15, 63, 255, 0.18) 0%, rgba(0, 132, 255, 0) 100%);
  &-box {
    display: flex;
    overflow: hidden;
    width: 100%;
    &-banner {
      width: 500px;
      img {
        width: 500px;
        height: 600px;
        cursor: pointer;
      }
    }
    .right {
      width: 83%;
      margin: 70px auto 20px;
      display: flex;
      flex-direction: column;
      :deep .t-input {
        height: 44px;
        border-radius: 8px;
      }
      &-tips {
        font-size: 36px;
        font-weight: 600;
        color: #3a63f3;
        padding-bottom: 32px;
        position: relative;
        &::after {
          content: "";
          width: 157px;
          height: 144px;
          background: url("~@/assets/bg.svg") no-repeat;
          position: absolute;
          right: 0;
          top: -10px;
        }
      }
      &-tab {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #97a3b7;
        padding-bottom: 10px;
        span {
          margin-right: 10px;
        }
        div {
          position: relative;
          margin-right: 10px;
          height: 30px;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: 600;
          &.active {
            color: #333333;
            display: flex;
            justify-content: center;
            &::after {
              content: "";
              position: absolute;
              width: 20px;
              height: 4px;
              background: #3a63f3;
              border-radius: 2px;
              bottom: 0;
            }
          }
        }
      }
      &-key {
        padding: 16px 0;
        color: #3c485c;
        font-weight: 600;
      }
      &-btn {
        margin: 10px 0;
        position: relative;
        span {
          position: absolute;
          top: -55px;
          left: 0;
          right: 0;
          text-align: left;
          color: red;
          white-space: nowrap;
        }
        button {
          width: 100%;
          border-radius: 8px;
          background: #3a63f3;
          &.t-is-disabled {
            background: #b5c7ff;
          }
        }
      }
      &-policy {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        span {
          color: #3a63f3;
          cursor: pointer;
        }
      }
      &-check {
        display: flex;
        justify-content: left;
        margin-top: 32px;
        align-items: flex-end;
        a {
          color: #3a63f3;
          text-decoration: none;
        }
      }
      &-timer {
        padding-bottom: 10px;
        text-align: center;
        button {
          background: #3a63f3;
          border-radius: 8px;
        }
      }
      &-consulting {
        line-height: 24px;
        div {
          padding-bottom: 20px;
        }
      }

      &-qrcode {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        align-items: center;
        width: 100%;
        .qrcode-title {
          width: 100%;
          height: 40px;
          font-size: 18px;
          margin-top: 30px;
          > span {
            vertical-align: -2px;
          }
        }
        .qrcode-box {
          width: 210px;
          height: 210px;
          overflow: hidden;
          scale: 1.6;
          padding-left: 6px;
        }
        .qrcode-explain {
          font-size: 16px;
          height: 100px;
        }
      }

      .bind-title {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        margin: 40px 0 30px;
      }
      .bind-wx-account {
        .account-box {
          > span {
            color: red;
            position: absolute;
            margin: 10px 0;
          }
          .control-btn {
            margin-top: 50px;
            display: flex;
            gap: 20px;
            width: 100%;
          }
        }
      }

      .other-login {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .wx-login {
          width: 120px;
          height: 34px;
          display: flex;
          align-items: center;
          border: 1px solid #d9e1ff;
          background: #f2f3ff;
          justify-content: center;
          border-radius: 3px;
          font-size: 14px;
          color: #232a3566;
          cursor: pointer;
          &:hover {
            background: #eeeeee;
          }
        }
      }
    }
  }
}
.pc.login {
  background: url("~@/assets/login.png") no-repeat center;
  align-items: center;
  .login-box {
    height: 600px;
    width: 1000px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    .right {
      width: 340px;
      :deep .t-input {
        background-color: #eff0f3;
        height: 38px;
        border-radius: 3px;
      }
      &-tips {
        &::after {
          display: none;
        }
      }
      &-btn {
        button {
          border-radius: 3px;
        }
      }
      &-check {
        align-items: center;
      }
    }
  }
}
</style>
