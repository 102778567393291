export const apiList = {
  //用户相关接口
  user: {
    getAuthToken: "/auth/oauth/token", //登录接口
    getSendSmsCode: "/admin/mobile/sendSmsCode/public/", //发送短信
    getSendEmailCode: "/admin/email/sendEmailCode/public/", //发送邮件
    getVeriSmsCode: "/admin/mobile/checkCode/", //验证手机验证码
    getVeriEmailCode: "/admin/email/checkCode/", //验证邮箱验证码
    getEditPassword: "/admin/user/findPwd", // 修改密码
    getAddRegister: "/admin/user/register/", // 手机号注册帐号
    // 新平台AIP
    getCheckToken: "/auth/oauth/check_token", // 验证token是否有效
    checkRedirectUrl: "/auth/client/check/redirectUri", // 校验客户端url
    getSpaAfficheList: "/general/roto/getShowList/", // 广告列表
    bindWechat: "/admin/social/bind" // 绑定微信
  }
}
