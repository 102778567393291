import request from "@/api/axios";
import qs from "querystring";
import { apiList as api } from "./apiList";

export function getUserToken(params) { // 帐号密码登录
  const grant_type = "password";
  return request({
    url: api.user.getAuthToken,
    method: "post",
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    params: { grant_type },
    data: qs.stringify({
      username: params.userName,
      password: params.password
    })
  });
}

export function getMobileToken(params) { // 手机号验证码登录
  const grant_type = "mobile";
  return request({
    url: api.user.getAuthToken,
    method: "post",
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    params: { grant_type },
    data: qs.stringify({
      mobile: "SMS@" + params.userName,
      code: params.password
    })
  });
}

export function getSendSmsCode(mobile, type) { // 发送手机验证码
  return request({
    url: api.user.getSendSmsCode + mobile + '/' + type,
    method: "get"
  });
}

export function getSendEmailCode(email, type) { // 发送邮箱验证码
  return request({
    url: api.user.getSendEmailCode + email + '/' + type,
    method: "post"
  });
}

export function getVeriSmsCode(mobile, type, code) { // 验证手机验证码
  return request({
    url: api.user.getVeriSmsCode + mobile + '/' + type + '/' + code,
    method: "get"
  });
}

export function getVeriEmailCode(email, type, code) { // 验证邮箱验证码
  return request({
    url: api.user.getVeriEmailCode + email + '/' + type + '/' + code,
    method: "get"
  });
}

export function getEditPassword(data) { // 修改密码
  return request({
    url: api.user.getEditPassword,
    method: "post",
    data
  });
}

export function getAddRegister(mobile) { // 手机号注册帐号
  return request({
    url: api.user.getAddRegister + mobile,
    method: "get"
  });
}

// 新平台AIP
export function getCheckToken(params) { // 验证token是否有效
  return request({
    url: api.user.getCheckToken,
    method: "get",
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    params
  });
}

export function checkRedirectUrl(params) { // 校验客户端url
  return request({
    url: api.user.checkRedirectUrl,
    method: "get",
    headers: {
      Authorization: "Basic Y29tbW9uOmNvbW1vbg=="
    },
    params
  });
}

export function getSpaAfficheList(categoryCode) {
  return request({
    url: api.user.getSpaAfficheList + categoryCode, // 广告列表
    method: "get",
  });
}

// 绑定微信
export function bindWechat(data, token) {
  return request({
    url: api.user.bindWechat, // 绑定微信
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`
    },
    data
  });
}
